import * as React from "react"

import Layout from "../components/layout"
import GetBlog from "../components/getBlog"
import Seo from "../components/seo"

const BlogPage = (props) => {
  return (

    <Layout>
      <Seo title="Blog" />
      <GetBlog />
      
    </Layout>
  )

}


export default BlogPage