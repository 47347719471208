import React from 'react'
import Axios from 'axios'
import Card from './card'
import ReactLoading from 'react-loading'


class GetBlog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      profile: {
        
        name: "",
        avatar: "",
        profileUrl: "",
      },
      item: [],
      isLoading: true,
      error: null
    }
  }

  mediumURL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@julianrosenthal"

  componentDidMount() {
    Axios.get(this.mediumURL)
      .then(data => {
        console.log(data)
        const feed = data.data.feed
        const avatar = feed.image;
        const profileUrl = feed.link;
        const res = data.data.items;
        const posts = res.filter(item => item.categories.length > 0)
        
        setTimeout(() => this.setState((pre) => ({
          profile: {
            ...pre.profile,
            
            profileUrl: profileUrl,
            avatar: avatar
          },
          posts: posts,
          isLoading: false
        })), 2000)
        
      })
      .catch((e) => {
        this.setState({ error: JSON.parse(e)})
      })
  }

  render() {
    let post;
    console.log('state: ', this.state)
    if (this.state.posts) {
      post = this.state.posts.map((post, index) => (
        <Card key={index} {...post} {...this.state.profile} {...index} />
      ))
    }

    if (this.state.isLoading) {
      console.log('loading')
      post = <ReactLoading className="a-centered" type={'cylon'} color={'#ffffff'} height={667} width={375} />
    }

    if (this.state.error) {
      let error = this.state.error.code ? this.state.error.code : this.state.error.name;
      let errorMsg = this.state.error.message;
      post = (
        <>
          <h2>{error}</h2>
          <p>{errorMsg}</p>
        </>
      )
    }

    return (
      <div className="container">
        <div className="row">
          {post}
        </div>
      </div>
    )
  }
}

export default GetBlog